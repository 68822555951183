/*
 * @Description:
 * @Author: YangXin
 * @Date: 2024-08-11 16:42:01
 * @listEditTime: Do not edit
 */
import  {jsonSources} from "./jsonSources"

export const dataSources = {
  ...jsonSources,
  //农牧动态
  nmdtItems: {
    url: 'http://localhost:8080/jzjd-admin/nync/compose?phone=15034933020',
    params: {
      pageSize: 10,
      current: 1,
    },
    method: 'GET',
    result:(res)=>{
      return res[1]["list"];
    }
  },
  //工作动态
  gzdtData: {
    url: 'https://bus.xiamentianyun.com:18089/mabp/pub/getPublicInfoList',
    params: {
      pageSize: 10,
      current: 1,
    },
    method: 'GET',
    result:(res)=>{
      return res["data"];
    }
  },

  //市场主体
  scztData: {
    url: 'https://bus.xiamentianyun.com:18089/mabp/pub/getPublicInfoList',
    params: {
      "area": "",
      "bodyName": "",
      "business": "",
      "corporateChampion": "",
      "enterpriseType": "",
      "isLeadingEnterprise": ""
    },
    method: 'POST',
    result:(res)=>{
      return res["data"]["data"];
    },
  },
  //读取分类信息
  // categorys: {
  //   url: '/api/getPostsPyCategoryId',
  //   params: {
  //     id:"",
  //     search:"",
  //     column_id:"",
  //     department_id:""
  //   },
  //   method: 'get',
  //   result:(res)=>{
  //
  //     return res["data"];
  //   },
  // },
  category: {
    url: '/api/getPostsPyCategoryId',
    params: {
      id:"2",
      search:"",
      column_id:"",
      department_id:""
    },
    method: 'get',
    result:(res)=>{
      return res["data"];
    },
  },

  post: {
    url: '/api/posts',
    params: {
      column_id:"",
      search:"",
      department_id:""
    },
    method: 'get',
    result:(res)=>{
      return res["data"];
    },
  },
  nyncbData: {
    url: '/api/nyncbData',
    params: {
      "area": "",
    },
    method: 'get',
    result:(res)=>{
      return res;
    },
  },

};


